import axios, { AxiosError, AxiosResponse, CanceledError } from 'axios';

import {
  GetUserResponse,
  GetUserRolesResponse,
  UpdateUserResponse,
  UpdateUserRoleRequest,
  UpdateUserRoleResponse,
  CreateOrgRequest,
  CreateOrgResponse,
  GetOrgsResponse,
  GetProjectsResponse,
  GetUsersResponse,
  UpdateOrgResponse,
  UpdateOrgRequest,
  GetSitesResponse,
  TourActionRequest,
  TourActionResponse,
  CreateProjectRequest,
  CreateProjectResponse,
  GetMetaByCategoryResponse,
  GetProjectAuditReponse,
  GetAssetsResponse,
  GetAllMetaResponse,
  GetAllAuditRequest,
  GetAllAuditReponse,
  GetDashMetricsResponse,
  GetSiteLevelsRequest,
  GetSiteLevelsResponse,
  GetSiteRequest,
  GetSiteResponse,
  GetProjectMediaResponse,
  GetProjectItemsResponse,
  DeleteProjectResponse,
  GetSharedProjectsResponse,
  UpdateSharedProjectRequest,
  CreateSharedProjectRequest,
  DeleteSharedProjectResponse,
  UpdateProjectRequest,
  UpdateProjectResponse,
  CreateUserRequest,
  CreateUserResponse,
  CreateMetaRequest,
  CreateMetaResponse,
  GetAssetResponse,
  UpdateMetaRequest,
  DeleteMetaResponse,
  UpdateMetaResponse,
  SetUserClaimRequest,
  SetUserClaimResponse,
} from '../models/admin-api/dto';
import { IOrganisation } from '../models/organisation';
import { UserDetail } from '../models/user';
import { createStandaloneToast } from '@chakra-ui/react';
const { toast } = createStandaloneToast();



const api = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_ADMIN_API_URL,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 400) {
      toast({
        title: 'An error occurred.',
        description: error.response?.data.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  },
);

// api.defaults.headers.common['x-functions-key'] =
//   process.env.REACT_APP_ADMIN_API_KEY;

const responseBody = (response: AxiosResponse) => response?.data;

const requests = {
  get: (url: string) => api.get(url).then(responseBody),
  getFile: (url: string) =>
    api.get(url, { responseType: 'arraybuffer' }).then(responseBody),

  postFile: (url: string, formData: object) =>
    axios
      .post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(responseBody),
  post: (url: string, body: object) => api.post(url, body).then(responseBody),
  put: (url: string, body: object) => api.put(url, body).then(responseBody),
  delete: (url: string) => api.delete(url).then(responseBody),
};

const Assets = {
  GetAssets: (siteId: number): Promise<GetAssetsResponse | any> =>
    requests.get('/api/Asset?siteId=' + siteId),
  GetAsset: (assetId: number): Promise<GetAssetResponse | any> =>
    requests.get('/api/Asset/' + assetId),
};

const TourActions = {
  Call: (body: TourActionRequest): Promise<TourActionResponse | any> =>
    requests.post('/api/Tours/TourActions', body),
};

const VTProjects = {
  AddVTProject: (
    body: CreateProjectRequest,
  ): Promise<CreateProjectResponse | any> =>
    requests.post('/api/Project/Create', body),
  UpdateVTProject: (
    body: UpdateProjectRequest,
  ): Promise<UpdateProjectResponse | any> =>
    requests.put('/api/Project/Update/' + body.Project.vtProjectFsid, body),
  DeleteVTProject: (projectId: string): Promise<DeleteProjectResponse | any> =>
    requests.delete('/api/Project/Delete/' + projectId),

  GetProjectItems: (
    projectId: string,
  ): Promise<GetProjectItemsResponse | any> =>
    requests.get('/api/Project/ProjectItemsByProject/' + projectId),

  GetVTProjects: (): Promise<GetProjectsResponse | any> =>
    requests.get('/api/Project'),

  SearchVTProjects: (searchQuery: string): Promise<GetProjectsResponse | any> =>
    requests.get('/api/Project?name=' + searchQuery),
};

const Sites = {
  GetSites: (orgId: number): Promise<GetSitesResponse | any> =>
    requests.get('/api/Site?organisationId=' + orgId),
  GetSite: (body: GetSiteRequest): Promise<GetSiteResponse | any> =>
    requests.post('/api/sites/GetSite', body),
  GetSiteLevels: (
    body: GetSiteLevelsRequest,
  ): Promise<GetSiteLevelsResponse | any> =>
    requests.post('/api/sites/GetSiteLevelBySite', body),
};

const Meta = {
  GetMetaByCategory: (
    entity: string,
    category: string,
  ): Promise<GetMetaByCategoryResponse | any> =>
    requests.get('/api/MetaData?entity=' + entity + '&category=' + category),

  GetAll: (): Promise<GetAllMetaResponse | any> =>
    requests.get('/api/MetaData'),

  CreateMeta: (body: CreateMetaRequest): Promise<CreateMetaResponse | any> =>
    requests.post('/api/MetaData/Create', body),

  UpdateMeta: (
    body: UpdateMetaRequest,
    metaDataId: number,
  ): Promise<UpdateMetaResponse | any> =>
    requests.put('/api/MetaData/Update/' + metaDataId, body),

  DeleteMeta: (metaDataId: number): Promise<DeleteMetaResponse | any> =>
    requests.delete('/api/MetaData/Delete/' + metaDataId),
};

const DashMetrics = {
  GetDashMetrics: (): Promise<GetDashMetricsResponse | any> =>
    requests.get('/api/Project/ProjectMetrics'),
};

const SharedProjects = {
  GetSharedProjectsByProjectId: (
    projectId: string,
  ): Promise<GetSharedProjectsResponse | any> =>
    requests.get('/api/Project/SharedProject?projectid=' + projectId),
  GetSharedProjects: (): Promise<GetSharedProjectsResponse | any> =>
    requests.get('/api/Project/SharedProject'),
  DeleteSharedProject: (
    spId: string,
  ): Promise<DeleteSharedProjectResponse | any> =>
    requests.delete('api/Project/SharedProject/Delete/' + spId),

  UpdateSharedProject: (
    body: UpdateSharedProjectRequest,
  ): Promise<Boolean | any> =>
    requests.put(
      '/api/Project/SharedProject/Update/' +
        body.sharedProjectLink.sharedProjectLinkId,
      body,
    ),

  CreateSharedProject: (
    body: CreateSharedProjectRequest,
  ): Promise<Boolean | any> =>
    requests.post('/api/Project/SharedProject/Create', body),

  RegenerateSharedProject: (
    sharedProjectLinkId: number,
  ): Promise<Boolean | any> =>
    requests.post(
      '/api/Project/SharedProject/Refresh/' + sharedProjectLinkId,
      {},
    ),

  GetViewData: (spId: string): Promise<string | any> =>
    requests.get('/api/Project/SharedProject/View/' + spId),
};

const Media = {
  GetProjectMedia: (
    projectId: string,
  ): Promise<GetProjectMediaResponse | any> =>
    requests.get('/api/Tours/GetMedia/' + projectId),
};

const Users = {
  CreateUser: (body: CreateUserRequest): Promise<CreateUserResponse | any> =>
    requests.post('/api/User/Create', body),

  CreateUserClaim: (
    body: SetUserClaimRequest,
  ): Promise<SetUserClaimResponse | any> =>
    requests.post('/api/User/SetClaims', body),
  UpdateUser: (
    userid: string,
    userObj: UserDetail,
  ): Promise<UpdateUserResponse | any> =>
    requests.put('/api/User/Update/' + userid, userObj),

  GetUser: (userId: string): Promise<GetUserResponse | any> =>
    requests.get('/api/User/' + userId),

  GetImportTemplate: (): Promise<any> =>
    requests.getFile('/api/users/GetUserTemplate'),
  ImportUsers: (formData: any): Promise<any> =>
    requests.postFile('/api/users/createBulk', formData),

  GetUserRoles: (userId: string): Promise<GetUserRolesResponse | any> =>
    requests.get('/api/User/Claims?searchUserId=' + userId),

  GetUsers: (): Promise<GetUsersResponse | any> => requests.get('/api/User'),

  UpdateUserRole: (
    req: UpdateUserRoleRequest, userClaimId:string ): Promise<UpdateUserRoleResponse | any> =>
    requests.put('/api/User/UpdateClaims/' + userClaimId, req),
};

const Organisations = {
  AddOrg: (body: CreateOrgRequest): Promise<CreateOrgResponse | any> =>
    requests.post('/api/organisations/create', body),
  UpdateOrg: (body: UpdateOrgRequest): Promise<UpdateOrgResponse | any> =>
    requests.post('/api/organisations/update', body),
  GetOrg: (orgId: number): Promise<IOrganisation | any> =>
    requests.get('/api/organisations/orgId=' + orgId),

  GetOrgs: (): Promise<GetOrgsResponse | any> =>
    requests.get('/api/Organisations'),

  GetOrgChart: (): Promise<GetOrgsResponse | any> =>
    requests.get('api/Organisations/OrganisationChart'),
};

const Audit = {
  GetProjectAudit: (projectId: string): Promise<GetProjectAuditReponse | any> =>
    requests.get('/api/Project/Audit/' + projectId),
  GetAllAudit: (body: GetAllAuditRequest): Promise<GetAllAuditReponse | any> =>
    requests.post('/api/audit/GetAuditLogs', body),
};

export default {
  api,
  VTProjects,
  Users,
  Organisations,
  Media,
  SharedProjects,
  DashMetrics,
  Sites,
  TourActions,
  Meta,
  Audit,
  Assets,
};
