import { useContext } from 'react';
import { IHotspot } from '../models/hotspot';
import { IScene } from '../models/scene';

export const prepScene = (scene: IScene) => {
  let updatedScene = scene;
  //Check ImageUrl
  if (scene.imageURL) {
    let updatedImageUrl = stripUrlSasKey(scene.imageURL);
    if (scene.imageURL != updatedImageUrl) {
      updatedScene.imageURL = updatedImageUrl;
    }
  }

  if (scene.previewURL) {
    let updatedPreviewUrl = stripUrlSasKey(scene.previewURL);
    if (scene.previewURL != updatedPreviewUrl) {
      updatedScene.previewURL = updatedPreviewUrl;
    }
  }
  //If there are any hotspots
  if (scene.hotspots.length > 0) {
    let updatedHotSpots = [];
    scene.hotspots.forEach((hs: IHotspot) => {
      updatedHotSpots.push(prepHotSpot(hs));
    });
    updatedScene.hotspots = updatedHotSpots;
  }
  //Return Original if no changes were made
  return updatedScene;
};

export const prepHotSpot = (hotSpot: IHotspot) => {
  let updatedHotSpot = hotSpot;

  //Check Linked Asset (Where Media Stored and Asset ID)
  if (hotSpot.linkedAsset && hotSpot.type !== 'asset') {
    let updatedLinkedAsset = stripUrlSasKey(hotSpot.linkedAsset);
    if (hotSpot.linkedAsset != updatedLinkedAsset) {
      updatedHotSpot.linkedAsset = updatedLinkedAsset;
    }
  }
  return updatedHotSpot;
};

export const prepScenes = (scenes: IScene[]) => {
  let updatedScenes = [];
  scenes.forEach(function (sc: IScene) {
    updatedScenes.push(prepScene(sc));
  });
  return updatedScenes;
};
//Remove SasKey from URL

export const stripUrlSasKey = (urlString: string) => {
  let sasSearchKey = '?sv=';
  if (urlString !== undefined) {
    if (urlString?.includes(sasSearchKey)) {
      const url = new URL(urlString);
      url.search = '';
      return url.href;
    }

    //Ignore and send back original URL String
    return urlString;
  }else
  {
    alert("URL String is null!");
    return null;
  }
};
