import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useKrpano from 'react-krpano-hooks';
import { useBuilderStore } from '../../contexts/RootStoreProvider';
import { observer } from 'mobx-react';
import builder from '../../layouts/builder';
import ProgressLoader from '../progressLoader';


const TourBuilderViewer = (props) => {

    const builderStore = useBuilderStore();
    //    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {


        if (builderStore.editorXML !== '' &&
            builderStore.currentScene !== '' &&
            callKrpano) {


            builderStore.callKrpano = callKrpano;
            builderStore.getKrpano = getKrpano;
            builderStore.setKrpano = setKrpano;

            callKrpano(
                "loadxml('" +
                builderStore.editorXML +
                "',NULL, MERGE|KEEPLOOKAT, BLEND(0.5));loadscene(" +
                builderStore.currentScene +
                ')'
            );
        }
    }
        , [builderStore.editorXML]);


    const { containerRef, setKrpano, getKrpano, callKrpano } = useKrpano({
        embeddingParams: {
            xml: null,
            target: 'react-krpano'
        },
        width: '100%',
        height: '85vh'
    }) as any;



    return (


        <>
            <div ref={containerRef} style={{ borderRadius: 5 }} />
        </>
    );
}



export default observer(TourBuilderViewer)



