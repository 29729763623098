import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useProjectStore } from '../../contexts/RootStoreProvider';
import { IFileUpload } from '../../models/fileUpload';
import { useDropzone } from 'react-dropzone';
import { Button, Center, GridItem, Icon, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
import { AiFillFileAdd } from 'react-icons/ai';
import { observer } from 'mobx-react';
import FileUploadItem from './fileUploadItem';
import { CompressFile, ConvertBase64 } from '../../helpers/fileHelpers';
import { TourActionRequest, TourActionResponse } from '../../models/admin-api/dto';
import '@pqina/pintura/pintura.css';
import { openDefaultEditor } from '@pqina/pintura';

import admin from '../../api/admin';

const FileUploader = (props: any) => {

    const projectStore = useProjectStore();
    // const acceptedImageType = 'image/png, image/jpeg';

    const {
        isDragActive,
        getRootProps,
        getInputProps
    } = useDropzone({

        // accept: props.type === 'video' ? acceptedVideoType : acceptedImageType,

        maxSize: 20971520,

        onDrop: (acceptedFiles: any, rejectedFiles: any) => {
            var updatedFiles = transformfiles(acceptedFiles);
            projectStore.setProjectUploads(updatedFiles);
        }
    });

    const dropText = isDragActive ? 'Drop the files here ...' : 'Drag \'n\' drop media files here, or click to select files';

    const activeBg = useColorModeValue('gray.100', 'gray.600');

    const borderColor = useColorModeValue(isDragActive ? 'teal.300' : 'gray.300', isDragActive ? 'teal.500' : 'gray.500',);

    const transformfiles = (acceptedFiles: any[]) => {

        var transformedFiles = [] as IFileUpload[];

        acceptedFiles.map((file) => {
            var newFile = {} as IFileUpload;

            //Images Only
            //Modified
            if (file.pintura) {
                newFile = {
                    File: file,
                    Progress: 0,
                    Status: 'Pending',
                    Preview: file.preview
                } as IFileUpload;
            }

            //New
            if (file.name && !file.pintura) {
                newFile = {
                    File: file,
                    Progress: 0,
                    Status: 'Pending',
                    Preview: URL.createObjectURL(file)
                } as IFileUpload;
            }

            if (!newFile.File) {
                newFile = file;
            }

            transformedFiles.push(newFile);
        });

        return transformedFiles;
    }

    const editImage = (image, done) => {

        console.log(image);
        const imageFile = image.pintura ? image.pintura.file : image;
        const imageState = image.pintura ? image.pintura.data : {};
    
        const editor = openDefaultEditor({
          src: imageFile,
          imageState
        });
    
        editor.on('close', () => {
          // the user cancelled editing the image
        });
    
        editor.on('process', ({ dest, imageState }) => {
          Object.assign(dest, {
            pintura: { file: imageFile, data: imageState }
          });
          done(dest);
        });
      };
    

    const handleImageEdit = (file: any, index: number) => {

        

        editImage(file, (output) => {
    
          const updatedFiles = [...projectStore.projectUploadFiles];
          // replace original image with new image
          updatedFiles[index] = output;
    
          // revoke preview URL for old image
          if (file.preview) URL.revokeObjectURL(file.preview);
    
          // set new preview URL
          Object.assign(output, {
            preview: URL.createObjectURL(output)
          });
    
          var transformedFiles = transformfiles(updatedFiles);
          projectStore.projectUploadFiles = transformedFiles;
        })
      }

    const setFileUploadProgress = (
        filename: string,
        progress: number,
        status: string
    ) => {
        console.log('FileName: ' + filename + ' ==> ' + progress);

        var fIndex = projectStore.projectUploadFiles.findIndex((f: IFileUpload) => f.File.name === filename);

        const uploads = [...projectStore.projectUploadFiles];

        var fileToUpdate = projectStore.projectUploadFiles[fIndex] as IFileUpload;

        if (fileToUpdate) {
            fileToUpdate.Progress = progress;
            fileToUpdate.Status = status;

            uploads[fIndex] = fileToUpdate;
            projectStore.setProjectUploads(uploads);
        }
    };

    const uploadFile = async (
        fUpload: IFileUpload,
        projectId: string,
        user: string,
        type: string
    ) => {
        projectStore.setIsUploading(true);
        setFileUploadProgress(fUpload.File.name, 0, 'Compressing File');

        //Compress
        var compressedFile;
        compressedFile = await CompressFile(fUpload.File);
        setFileUploadProgress(fUpload.File.name, 0, 'File Compressed');
        const base64string = await ConvertBase64(fUpload.File);

        var fileName =
            fUpload.Name !== undefined
                ? fUpload.Name
                : fUpload.File.name.substring(0, fUpload.File.name.lastIndexOf('.'));

        var addNewFileObj = {
            name: fileName,
            fileName: fUpload.File.name,
            projectId: projectId,
            fileContent: base64string.toString().split(',')[1],
            type: type
        };

        var addMediaRequest = {
            action: 'addMedia',
            value: addNewFileObj,
            projectId: projectId
        } as TourActionRequest;

        var response = (await admin.TourActions.Call(addMediaRequest)) as TourActionResponse;

        if (response.isSuccess) {
            try {
                URL.revokeObjectURL(fUpload.Preview);
            } catch (err) {
                alert('Unable to dispose of Preview for Scene!');
            }

            setFileUploadProgress(fUpload.File.name, 80, 'Creating File');
            setFileUploadProgress(fUpload.File.name, 90, 'File Created');
            setFileUploadProgress(fUpload.File.name, 100, 'Completed');
            return response.result;
        }
    };

    const handleUploadFiles = () => {
        var newFileCounter = 0;
        
        projectStore.projectUploadFiles.forEach(async (fUpload: IFileUpload, index) => {
            var newFile = await uploadFile(fUpload, props.projectId, props.userId, props.type);
            newFileCounter ++ ;
                       
            
            if (projectStore.isUploading && newFileCounter == projectStore.projectUploadFiles.length)
                {
                    projectStore.setIsUploading(false);
                    projectStore.setProjectUploads([]);
                    props.refreshItems();

                }
        });



    }

    const handleFileRemove = (fileName: string) => {
        const updatedFiles = projectStore.projectUploadFiles.filter((file: IFileUpload) => file.File.name !== fileName);
        projectStore.setProjectUploads(updatedFiles);
    }

    return (

        <SimpleGrid border="1px dashed"
            borderColor={borderColor} columns={{ base: 1, sm: 1, md: 1, lg: 1 }} spacing={5}>
            <GridItem colSpan={1} >
                <Center
                    p={3}
                    m={2}
                    cursor="pointer"
                    bg={isDragActive ? activeBg : 'transparent'}
                    _hover={{ bg: activeBg }}
                    transition="background-color 0.2s ease"
                    borderRadius={4}
                    border="3px dashed"
                    borderColor={borderColor}
                    {...getRootProps()}
                >
                    <input {...getInputProps()} />
                    <Icon as={AiFillFileAdd} mr={2} />
                    <p>{dropText}</p>
                </Center>

            </GridItem>
            <GridItem>
                {
                    projectStore.projectUploadFiles.map((file: IFileUpload, index: number) =>
                        <FileUploadItem file={file} removeFileItem={handleFileRemove} index={index} editImageItem={handleImageEdit} />
                    )
                }
            </GridItem>

            {projectStore.projectUploadFiles.length > 0 ? (
                <Button variant={'brand'} loadingText="Uploading Files...Please Wait" isLoading={projectStore.isUploading} onClick={() => handleUploadFiles()}> Upload Files</Button>
            ) : (<></>)
            }

        </SimpleGrid >
    )
}

FileUploader.propTypes = {
    projectId: PropTypes.string,
    userId: PropTypes.any,
    type: PropTypes.any,
    refreshItems: PropTypes.any
}

export default observer(FileUploader)