import { IconButton, Stack, Tooltip } from "@chakra-ui/react";
import { CustomCellRendererProps } from "ag-grid-react";
import { FaTrash } from "react-icons/fa";
import ProgressLoader from "../../../progressLoader";
import { useState } from "react";

export default (params: CustomCellRendererProps) => {


    const [isLoading, SetIsLoading] = useState(false);


    return (
        <>
            {isLoading ? <ProgressLoader /> :
                <Stack direction="row" spacing={4}>
                    
                    <Tooltip label='Delete'>
                        <IconButton icon={<FaTrash />} aria-label={'Delete'} size='sm' colorScheme='red' variant='outline'  />
                    </Tooltip>
                </Stack>
            }
        </>
    )
}