import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/auth-context';
import { RootStoreProvider } from './contexts/RootStoreProvider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";

import App from './App';
import './assets/css/App.css';
import { createStandaloneToast } from '@chakra-ui/react'

const { ToastContainer, toast } = createStandaloneToast()

LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-063391}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Exactly_Tech_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Theia360}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Theia360}_need_to_be_licensed___{Theia360}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{12_July_2025}____[v3]_[01]_MTc1MjI3NDgwMDAwMA==562be590dd1c01f231f1e992579cee6d");

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <RootStoreProvider>
      <AuthProvider>
        <App />    
        <ToastContainer />{process.env.NODE_ENV}
      </AuthProvider>
    </RootStoreProvider>
  </BrowserRouter>,
);




serviceWorkerRegistration.unregister();