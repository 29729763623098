import {
  IVTProject,
  IVtBuildProject,
  updateTourObj,
} from '../models/vtProject';
import { makeAutoObservable } from 'mobx';
import { RootStore } from './rootStore';
import { IHotspot } from '../models/hotspot';
import { IScene } from '../models/scene';
import {
  GetAssetsResponse,
  TourActionRequest,
  TourActionResponse,
} from '../models/admin-api/dto';
import { useToast } from '@chakra-ui/react';
import { CompressFile, ConvertBase64 } from '../helpers/fileHelpers';
import { prepScenes } from '../helpers/sasKeyHelper';
import admin from '../api/admin';
import { ILayer } from '../models/layer';
import { IAsset } from '../models/asset';

export class BuilderStore {
  //Currently edited Project
  editProject: IVTProject | undefined = undefined;
  //Working Variables
  editorProjectFSId: string = '';
  editorProjectName: string = '';
  editorProjectSiteId: number = undefined;

  currentViewProjectName: string = undefined;

  updatedEditorXML: string = '';
  editorXML: string = '';
  editorDescription: string = '';
  currentScene: string = '';
  currentHotspot: IHotspot | undefined;
  currentUserId: string = ';';
  showHotSpotDialog = false;
  hotSpotDialogType = 'Hotspot';

  startupScene: string = '';
  hotspots: IHotspot[] = [];
  layers: ILayer[] = [];
  scenes: IScene[] = [];
  assets: IAsset[] = [];

  isLoading: boolean = false;
  hasUnsavedChanges = false;
  requiresRefresh = false;

  //Required for Krpano
  callKrpano: any;
  setKrpano: any;
  getKrpano: any;
  spheretoscreenKrpano: any;

  //Rootstore
  root: RootStore;
  toast = useToast();

  constructor(root: RootStore) {
    this.root = root;
    makeAutoObservable(this);
    window['VTBuilderStore'] = this;
  }

  //Sets the current scene and moves the tour to it.
  setCurrentScene(sc: string, lookat?: string) {
    if (this.requiresRefresh && this.updatedEditorXML !== undefined) {
      this.editorXML = this.updatedEditorXML;
      this.updatedEditorXML = undefined;
    }

    this.currentScene = sc;
    if (lookat) {
      this.callKrpano(
        'loadscene(' +
          this.currentScene +
          ', null, MERGE|KEEPVIEW,BLEND(0.5));lookto(' +
          lookat +
          ');',
      );
    } else {
      this.callKrpano(
        'loadscene(' +
          this.currentScene +
          ', null, MERGE|KEEPVIEW,BLEND(0.5));',
      );
    }
  }

  setHasUnsavedChanges(isDirty: boolean) {
    console.log('Setting Unsaved to ' + isDirty);
    this.hasUnsavedChanges = isDirty;
  }

  setRequiresRefresh(required: boolean) {
    console.log('Setting Requires Refresh to ' + required);
    this.requiresRefresh = true;
  }

  //Save the tour with the latest state
  async saveEditProject(
    refreshXML: boolean,
    scene: string,
    message: string | undefined,
  ) {
    this.isLoading = true;

    try {
      if (this.editorProjectFSId !== '' && this.scenes.length > 0) {
        var transFormedScenes = JSON.parse(JSON.stringify(this.scenes));
        let preppedScenes = prepScenes(transFormedScenes);

        var updateTourRequest = {
          projectId: this.editorProjectFSId,
          scenes: preppedScenes,
          layers: this.layers,
          startScene: this.startupScene,
        } as updateTourObj;

        var callUpdateTourRequest = {
          action: 'updateTour',
          projectId: this.editorProjectFSId,
          value: updateTourRequest,
        } as TourActionRequest;

        admin.TourActions.Call(callUpdateTourRequest).then(
          (response: TourActionResponse) => {
            this.updatedEditorXML = response.result.tourXml;

            if (refreshXML) {
              this.editorXML = response.result.tourXml;
            }
            this.setHasUnsavedChanges(false);

            if (response.isSuccess) {
              this.toast({
                title: 'Success',
                description: message ? message : 'Project Updated',
                status: 'success',
                duration: 2000,
                isClosable: true,
              });
            } else {
              this.toast({
                title: 'Error!',
                description: 'Error Saving Tour: ' + response.message,
                status: 'error',
                duration: 2000,
                isClosable: true,
              });
            }
          },
        );
        // .finally(() => {
        //   if (refreshXML === true) {
        //     var currentLookat =
        //       this.getKrpano('view.hlookat') +
        //       ',' +
        //       this.getKrpano('view.vlookat') +
        //       ',' +
        //       this.getKrpano('view.fov');

        //     this.setCurrentScene(scene, currentLookat);
        //   }
        // });
      } else {
        this.toast({
          title: 'Error!',
          description: 'Error Saving Tour: No enough data to create',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (error: any) {
      this.isLoading = false;
      this.toast({
        title: 'Error!',
        description: 'Error Saving Project - Please try again.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      console.log('Error Saving Project', console.log(error));
    }
    this.isLoading = false;
  }

  async deleteScene(name: any) {
    var isSceneInHotSpot = false;
    var isSceneStartup = false;

    this.scenes.map((sc) => {
      sc.hotspots.map((hs) => {
        if (hs.linkedScene.toLowerCase().trim() === name.toLowerCase().trim()) {
          isSceneInHotSpot = true;
        }
      });
    });

    if (this.startupScene.toLowerCase().trim() === name.toLowerCase().trim()) {
      isSceneStartup = true;
    }

    //Go Ahead and delete scene - safe
    if (isSceneStartup === false && isSceneInHotSpot === false) {
      var scIndex = this.scenes.findIndex((sc) => sc.name === name);
      //Remove Scene
      if (scIndex !== null) {
        this.scenes.splice(scIndex, 1);
        this.currentScene = this.scenes[0].name;
        this.saveEditProject(
          true,
          this.currentScene,
          'Scene has been successfully removed',
        );
      }
    } else {
      var noDeleteReason = '';

      if (isSceneInHotSpot) {
        noDeleteReason =
          noDeleteReason +
          '\n >> Scene is connected to an existing hotspot - please update hotspot';
      }

      if (isSceneStartup) {
        noDeleteReason =
          noDeleteReason +
          '\n >> Scene is a Start Up Scene, please update startup scene';
      }

      this.toast({
        title: 'Unable to remove Scene!',
        description:
          'Unable to remove scene for the following reasons ' + noDeleteReason,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }

  //Rename current scene
  async renameCurrentScene(sceneName: string) {
    //Check to see if the name requested is actually different
    if (this.currentScene.trim() !== sceneName.trim()) {
      let currSceneName = this.currentScene;

      if (this.currentScene) {
        var currentSceneIndex = this.scenes.findIndex(
          (sc: IScene) => sc.name === this.currentScene,
        );

        if (currentSceneIndex !== -1) {
          let newScenes = [...this.scenes];
          newScenes[currentSceneIndex] = {
            ...newScenes[currentSceneIndex],
            name: sceneName.trim(),
          };

          //Check if this is the startup seen and update.
          if (this.startupScene.trim() === currSceneName) {
            this.startupScene = sceneName;
          }

          //now we have updated the scene name, we need to iterate through all the hotspots and ensure that there are no linked scenes

          newScenes.forEach((sc: IScene) => {
            //check hotspots in this scene for any hotspots that use this scene
            const updateHotSpots = sc.hotspots.filter(
              (hs) => hs.linkedScene === currSceneName,
            );

            //if there are any hotspots, they need to be updated.
            if (updateHotSpots.length > 0) {
              //Create new version of the scenes hotspots.
              let newHotSpotsForScene = [...sc.hotspots];
              //Get Locations of Hotspots that need updating
              updateHotSpots.forEach((hs: IHotspot) => {
                var index = updateHotSpots.findIndex(
                  (hss) => hss.name === hs.name,
                );
                if (index !== -1) {
                  //Update Linked Scene on this hotspot with updated name
                  newHotSpotsForScene[index] = {
                    ...newHotSpotsForScene[index],
                    linkedScene: sceneName,
                  };
                }
              });
              sc.hotspots = newHotSpotsForScene;
            }
          });

          this.scenes = newScenes;
          this.setCurrentScene(sceneName);
        } else {
          this.toast({
            title: 'Error!',
            description: 'Unable to rename Scene, please try again!',
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
        }
      }
      this.saveEditProject(
        true,
        this.currentScene,
        'Scene has been successfully renamed',
      );
    }
  }

  //Tour Toolbox Functions
  //Add New Hotspot to Tour
  addNewHotSpot(type: string) {
    var h = this.getKrpano('view.hlookat');
    var v = this.getKrpano('view.vlookat');
    var hs_name =
      type + '_' + Math.floor(Math.random() * 1000) + '_' + this.currentScene;

    this.callKrpano('addhotspot(' + hs_name + ')');

    var imgUrl = '';
    var onClick = 'selectHotSpot';
    var onUp = 'onUpHotSpot';

    var onLoaded = 'undefined';

    if (type === 'hotspot') {
      imgUrl = '/krpano/skin/arrow.png';
      // onClick = 'selectHotSpot';
      onLoaded = 'do_crop_animation(128,128,5,true)';
    }
    if (type === 'iframe') {
      imgUrl = '/krpano/skin/iframe.png';
    }
    if (type === 'weburl') {
      imgUrl = '/krpano/skin/iframe.png';
    }
    if (type === 'note') {
      imgUrl = '/krpano/skin/note.png';
    }
    if (type === 'asset') {
      imgUrl = '/krpano/skin/asset.png';
    }
    if (type === 'media') {
      imgUrl = '/krpano/skin/media.png';
    }

    if (type === 'video') {
      imgUrl = '/krpano/skin/video.png';
    }

    this.setKrpano('hotspot[' + hs_name + '].url', imgUrl);
    this.setKrpano('hotspot[' + hs_name + '].scale', 0.3);
    this.setKrpano('hotspot[' + hs_name + '].ath', h);
    this.setKrpano('hotspot[' + hs_name + '].atv', v);
    this.setKrpano('hotspot[' + hs_name + '].distorted', true);
    this.setKrpano('hotspot[' + hs_name + '].scale', 0.5);

    this.setKrpano('hotspot[' + hs_name + '].ondown', 'draghotspot');
    this.setKrpano('hotspot[' + hs_name + '].onup', 'onUpHotSpot');
    this.setKrpano('hotspot[' + hs_name + '].onclick', 'selectHotSpot');
    this.setKrpano('hotspot[' + hs_name + '].onloaded', onLoaded);

    this.setKrpano('hotspot[' + hs_name + '].type', type);
    this.setKrpano('hotspot[' + hs_name + '].text', 'undefined');
    this.setKrpano('hotspot[' + hs_name + '].fPlanLocation', 'undefined');
    this.setKrpano('hotspot[' + hs_name + '].fPlanURL', 'undefined');

    const hotSpotObj = {
      name: hs_name,
      id: hs_name,
      ath: h,
      atv: v,
      scene: this.currentScene,
      type: type,
      url: imgUrl,
      onclick: onClick,
      onup: onUp,
      text: 'undefined',
      fPlanLocation: 'undefined',
      fPlanURL: 'undefined',
    } as IHotspot;

    var scIndex = this.scenes.findIndex((sc) => sc.name === this.currentScene);
    this.scenes[scIndex].hotspots.push(hotSpotObj);

    this.saveEditProject(false, this.currentScene, 'New Hotspot Created');
  }

  getAssetRepairDetails(assetid: string) {
    if (this.assets.length === 0) {
      // this.getAssetListForProperty();
    }
  }

  //Tour Toolbox Functions
  //Add New Hotspot to Tour
  addNewFpCamera(sceneName: string) {
    if (sceneName) {
      var hs_name = 'fpCam' + sceneName;

      this.callKrpano('addLayer(' + hs_name + ')');

      this.setKrpano(
        'layer[' + hs_name + '].url',
        '/krpano/radar/camicon_red.png',
      );
      this.setKrpano('layer[' + hs_name + '].x', 10);
      this.setKrpano('layer[' + hs_name + '].y', 20);
      this.setKrpano('layer[' + hs_name + '].zorder', 1);
      this.setKrpano('layer[' + hs_name + '].ondown', 'fpcam_draglayer()');
      this.setKrpano('layer[' + hs_name + '].parent', 'floorplan');
      this.setKrpano('layer[' + hs_name + '].onclick', 'selectfpCam_onClick()');
      this.setKrpano('layer[' + hs_name + '].onup', 'fpcam_onuplayer()');
      this.setKrpano('layer[' + hs_name + '].type', 'fpcam');
      this.setKrpano('layer[' + hs_name + '].scene', sceneName);

      const layerObj = {
        name: hs_name,
        url: '/krpano/radar/camicon_red.png',
        x: '10',
        y: '20',
        type: 'fpcam',
        zorder: '1',
        ondown: 'fpcam_draglayer()',
        parent: 'floorplan',
        onclick: 'selectfpCam_onClick()',
        onup: 'fpcam_onuplayer()',
        scene: sceneName,
      } as ILayer;

      this.layers.push(layerObj);
    } else {
      alert('Missing Scene Name - Please try again');
    }
    // this.saveEditProject(false, this.currentScene, 'New Hotspot Created');
  }

  //Add Floorplan Function to the Tour
  addFloorplan() {
    this.callKrpano('addLayer(floorplan)');
    this.setKrpano('layer[floorplan].url', '/krpano/radar/map.png');
    this.setKrpano('layer[floorplan].keep', 'true');
    this.setKrpano('layer[floorplan].maskchildren', 'true');
    this.setKrpano('layer[floorplan].handcursor', 'true');
    this.setKrpano('layer[floorplan].capture', 'false');
    this.setKrpano('layer[floorplan].scale', '0.25');
    this.setKrpano('layer[floorplan].align', 'bottomleft');
    this.setKrpano('layer[floorplan].onclick', 'openmap();');

    const layerObj = {
      name: 'floorplan',
      url: '/krpano/radar/map.png',
      keep: 'true',
      maskchildren: 'true',
      handcursor: 'true',
      capture: 'false',
      scale: '0.25',
      align: 'bottomleft',
      onclick: 'openmap()',
    } as ILayer;

    this.layers.push(layerObj);
  }

  //Clear Cache so no overlap on additional project
  clearBuilderData() {
    this.requiresRefresh = false;
    this.updatedEditorXML = undefined;
    this.currentScene = undefined;
    this.currentHotspot = undefined;
    this.editProject = undefined;
    this.startupScene = undefined;
    this.editorXML = undefined;
    this.editorProjectFSId = undefined;
    this.scenes = [];
    this.hotspots = [];
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  //Initialize Tour Builder
  async startBuilder(userId: string, projectId: string) {
    this.clearBuilderData();

    this.setIsLoading(true);
    this.setHasUnsavedChanges(false);

    var buildXmlResponse = (await admin.TourActions.Call({
      value: projectId,
      projectId: projectId,
      action: 'initBuild',
    } as TourActionRequest)) as TourActionResponse;

    //Store The Current User ID on Start of Builder

    this.currentUserId = userId;

    if (buildXmlResponse !== undefined) {
      if (buildXmlResponse.isSuccess) {
        var buildObj = buildXmlResponse.result as IVtBuildProject;

        this.editorProjectFSId = projectId;
        this.editorProjectName = buildObj.projectName;
        this.editorXML = buildObj.editXml;
        this.scenes = buildObj.scenes;
        this.hotspots = buildObj.hotspots;
        this.editorProjectSiteId = buildObj.siteId;
        this.startupScene = buildObj.startScene;
        this.currentScene = buildObj.startScene;
        this.currentUserId = userId;
        this.editorDescription = buildObj['projectDescription'];
        this.editorProjectSiteId = buildObj.siteId;

        this.getSiteAssets();

        this.setIsLoading(false);

        return true;
      } else {
        this.toast({
          title: 'Error!',
          description: 'Error obtaining build detail - Please try again.',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });

        this.setIsLoading(false);

        return false;
      }
    }
    this.setIsLoading(false);
    return false;
  }

  getSiteAssets() {
    var assetLocation = process.env.REACT_APP_ASSET_LOCATION;
    var assetsEnabled = process.env.REACT_APP_ASSET_MODULE;

    //Internal Asset Storage
    if (
      assetLocation == '0' &&
      this.editorProjectSiteId !== undefined &&
      assetsEnabled == 'true'
    ) {
      this.setIsLoading(true);
      admin.Assets.GetAssets(this.editorProjectSiteId)
        .then((resp: GetAssetsResponse) => {
          this.assets = resp.result;
        })
        .finally(() => {
          this.setIsLoading(false);
        });
    }
  }

  forceSave() {
    this.setRequiresRefresh(true);
    this.saveEditProject(false, this.currentScene, 'Hotspot Location Updated');
  }

  //Update Scene Image from Builder
  uploadSceneImage = async (
    file: File,
    sceneName: string,
    projectId: string,
  ) => {
    // this.isUploading = true;

    console.log('Compressing ' + file.name);

    //Compress
    var compressedFile = await CompressFile(file).catch((error: any) => {
      this.toast({
        title: 'Error Compressing File!',
        description: 'Error Compressing File: ' + error,
        status: 'error',
        duration: 26000,
        isClosable: true,
      });
    });
    const base64string = await ConvertBase64(compressedFile).catch(
      (error: any) => {
        this.toast({
          title: 'Error Converting File!',
          description: 'Base64String Error: ' + error,
          status: 'error',
          duration: 26000,
          isClosable: true,
        });
      },
    );

    var addNewSceneObj = {
      sceneName: sceneName,
      fileName: file.name,
      projectId: projectId,
      fileContent: base64string.toString().split(',')[1],
    };

    var addSceneRequest = {
      action: 'addScene',
      value: addNewSceneObj,
      projectId: projectId,
    } as TourActionRequest;

    var response = (await admin.TourActions.Call(
      addSceneRequest,
    )) as TourActionResponse;

    if (response.isSuccess) {
      this.toast({
        title: 'Success',
        description: 'Scene upload - Complete!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });

      return response.result;
    }
  };

  //Set the start POV for the current scene
  setDefaultView() {
    var sceneId = this.scenes.findIndex((sc) => sc.name === this.currentScene);
    var updatedScenes = this.scenes;

    updatedScenes[sceneId].vlookat = this.getKrpano('view.vlookat');
    updatedScenes[sceneId].hlookat = this.getKrpano('view.hlookat');
    updatedScenes[sceneId].fov = this.getKrpano('view.fov');

    this.scenes = updatedScenes;

    this.saveEditProject(true, this.currentScene, 'Scene Default View Updated');

    return true;
  }

  //Update the Startup Scene for the Tour
  setStartupScene() {
    this.startupScene = this.currentScene;
    //Get Correct Scene in Collection
    var sceneId = this.scenes.findIndex((sc) => sc.name === this.currentScene);
    var updatedScenes = this.scenes;
    updatedScenes[sceneId].isStartup = true;

    this.scenes = updatedScenes;

    this.saveEditProject(
      false,
      this.currentScene,

      'Start Up Scene Updated',
    );
    return true;
  }

  async getEditTourDetails(projectId: string) {
    var buildXmlResponse = (await admin.TourActions.Call({
      value: projectId,
      projectId: projectId,
      action: 'initBuild',
    } as TourActionRequest)) as TourActionResponse;

    if (buildXmlResponse !== undefined) {
      if (buildXmlResponse.isSuccess) {
        var buildObj = buildXmlResponse.result as IVtBuildProject;
        this.scenes = buildObj.scenes;
      }
    }
  }

  //Sets this edit project for the session
  setCurrentEditProject(project: IVTProject) {
    this.editProject = project;
  }

  //Called from The Darkside
  fpcam_onuplayer(name: string, x: string, y: string) {
    //Doesnt Save , just updates location and updates have set changes!

    var sceneIndex = this.scenes.findIndex(
      (sc) => sc.name.toLocaleLowerCase() === name.replace('fpcam_', ''),
    );

    if (sceneIndex != -1) {
      var foundScene = this.scenes[sceneIndex];
      foundScene.mapX = x;
      foundScene.mapY = y;
      this.scenes[sceneIndex] = foundScene;
    }
  }

  //Called from The Darkside
  saveVTProject(name: any, ath: any, atv: any) {
    //Doesnt Save , just updates location and updates have set changes!

    if (this.currentScene !== '') {
      var currSceneIndex = this.scenes.findIndex(
        (sc) => sc.name === this.currentScene,
      );
      var hotSpotIndex = this.scenes[currSceneIndex].hotspots.findIndex(
        (hs) => hs.name.toLocaleLowerCase() === name.toLocaleLowerCase(),
      );
      var newHotSpot = this.scenes[currSceneIndex].hotspots[hotSpotIndex];

      newHotSpot.ath = ath;
      newHotSpot.atv = atv;

      this.scenes[currSceneIndex].hotspots[hotSpotIndex] = newHotSpot;

      this.setHasUnsavedChanges(true);
    } else {
      this.toast({
        title: 'Error!',
        description: 'Unable to Update Hotspot Location - Please Try Again!',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }

  // Called from the Darkside
  getSelectedHotspot(name: any) {
    this.getHotSpotByName(name);
  }

  getSelectedFpCam(name: string) {
    var response = prompt(
      'Which scene should this be associated to (' + name + ')',
    );
    return response;
  }

  //Global Show Hotspot Page in Builder
  setShowHotspotDialog(show: boolean) {
    this.showHotSpotDialog = show;
  }

  clearCurrentHotspot() {
    this.currentHotspot = undefined;
  }

  //Associate a Hotspot in the tour
  async setHotSpotLinkedData(ld: string, type: string) {
    this.isLoading = true;

    if (ld) {
      var currSceneIndex = this.scenes.findIndex(
        (sc) => sc.name === this.currentScene,
      );

      var hotSpotIndex = this.scenes[currSceneIndex].hotspots.findIndex(
        (hs) => hs.name === this.currentHotspot?.name,
      );

      if (type.toLowerCase() === 'hotspot') {
        this.scenes[currSceneIndex].hotspots[hotSpotIndex].linkedScene = ld;
      }

      if (type.toLowerCase() === 'iframe') {
        this.scenes[currSceneIndex].hotspots[hotSpotIndex].text = ld;
      }

      if (type.toLowerCase() === 'weburl') {
        this.scenes[currSceneIndex].hotspots[hotSpotIndex].text = ld;
      }
      if (type.toLowerCase() === 'asset') {
        this.scenes[currSceneIndex].hotspots[hotSpotIndex].linkedAsset = ld;
      }
      if (type.toLowerCase() === 'note') {
        this.scenes[currSceneIndex].hotspots[hotSpotIndex].text = ld;
      }
      if (type.toLowerCase() === 'media') {
        this.scenes[currSceneIndex].hotspots[hotSpotIndex].linkedAsset = ld;
      }
      if (type.toLowerCase() === 'video') {
        this.scenes[currSceneIndex].hotspots[hotSpotIndex].linkedAsset = ld;
      }
      if (type.toLowerCase() === 'assetLocation') {
        this.scenes[currSceneIndex].hotspots[hotSpotIndex].fPlanLocation = ld;
      }
      await this.saveEditProject(
        false,
        this.currentScene,
        'Hotspot Scene Updated',
      );

      //Updated Selected , this way all modals will update accordingly.
      this.currentHotspot = this.scenes[currSceneIndex].hotspots[hotSpotIndex];

      this.isLoading = false;
    } else {
      alert('ID From Hotspot Unavailable!');
    }
  }

  //Sets a new location of a hotspot
  getHotSpotByName(name: any) {
    let sceneFound = false;
    this.scenes.map((sc) => {
      sc.hotspots.map((hs) => {
        if (hs.name.toLowerCase().trim() === name.toLowerCase().trim()) {
          sceneFound = true;
          this.currentHotspot = hs;

          if (
            this.currentHotspot.type === 'video' ||
            this.currentHotspot.type === 'media' ||
            this.currentHotspot.type === 'Image'
          ) {
            this.hotSpotDialogType = 'media';
            this.setShowHotspotDialog(true);
          }

          if (this.currentHotspot.type === 'note') {
            this.hotSpotDialogType = 'note';
            this.setShowHotspotDialog(true);
          }

          if (this.currentHotspot.type === 'iframe') {
            this.hotSpotDialogType = 'iframe';
            this.setShowHotspotDialog(true);
          }

          if (this.currentHotspot.type === 'weburl') {
            this.hotSpotDialogType = 'weburl';
            this.setShowHotspotDialog(true);
          }

          if (this.currentHotspot.type === 'hotspot') {
            this.hotSpotDialogType = 'hotspot';
            this.setShowHotspotDialog(true);
          }

          if (this.currentHotspot.type === 'fpcamera') {
            this.hotSpotDialogType = 'media';
            this.setShowHotspotDialog(true);
          }

          if (this.currentHotspot.type === 'asset') {
            this.hotSpotDialogType = 'asset';
            this.setShowHotspotDialog(true);
          }
        }
      });
    });
    if (sceneFound === false) {
      this.toast({
        title: 'Error!',
        description: 'Unable to access Scene for Hotspot - Please Try Again!',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }

  setCurrentViewProjectName(name: string | undefined) {
    this.currentViewProjectName = name;
  }

  //Removes the Hotspot from the viewer and sends an update to the server
  removeHotspot() {
    var hsScene = this.currentScene;
    var hsName = this.currentHotspot?.name;

    var scIndex = this.scenes.findIndex((sc) => sc.name === hsScene);
    var hsIndex = this.scenes[scIndex].hotspots.findIndex(
      (hs) => hs.name === hsName,
    );

    if (scIndex !== null && hsIndex !== null) {
      this.scenes[scIndex].hotspots.splice(hsIndex, 1);
      this.currentHotspot = undefined;
    }

    this.callKrpano('removehotspot(' + hsName + ')');

    this.saveEditProject(false, this.currentScene, 'Hotspot Removed');
    this.setShowHotspotDialog(false);
  }

  async startViewer(projectId: string) {
    var viewXmlResponse = (await admin.TourActions.Call({
      value: projectId,
      action: 'getViewerXML',
    } as TourActionRequest)) as TourActionResponse;
    this.setCurrentViewProjectName('');

    if (viewXmlResponse !== undefined) {
      if (viewXmlResponse.isSuccess) {
        this.setCurrentViewProjectName(viewXmlResponse.result.projectName);

        return viewXmlResponse.result;
      } else {
        this.toast({
          title: 'Error!',
          description: 'Unable to access view detail - Please Try Again!',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });

        return false;
      }
    }
    return false;
  }

  onUpSelectedHotspot(name: any) {}
}
