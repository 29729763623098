import { makeAutoObservable } from 'mobx';
import {
  ISharedProjectLink,
  ISharedProjectLinkListObj,
} from '../models/SharedProjectLink';
import { IFileUpload } from '../models/fileUpload';
import { IScene } from '../models/scene';
import { IVTProject, createTourObj } from '../models/vtProject';
import { RootStore } from './rootStore';
import admin from '../api/admin';
import {
  CreateProjectRequest,
  CreateProjectResponse,
  CreateSharedProjectRequest,
  CreateSharedProjectResponse,
  DeleteProjectResponse,
  GetProjectsResponse,
  GetSharedProjectsResponse,
  TourActionRequest,
  TourActionResponse,
  UpdateProjectRequest,
  UpdateProjectResponse,
  UpdateSharedProjectRequest,
  UpdateSharedProjectResponse,
} from '../models/admin-api/dto';
import imageCompression from 'browser-image-compression';
import { Edge } from 'reactflow';
import { useToast } from '@chakra-ui/react';
import { CompressFile, ConvertBase64 } from '../helpers/fileHelpers';

export class ProjectStore {
  projects: IVTProject[] = [];
  sharedProjects: ISharedProjectLinkListObj[] = [];
  selectedProject: IVTProject | undefined = undefined;
  isLoadingProjects = false;
  projectUploadFiles: IFileUpload[] = [];
  projectUploadHotspots: Edge[] = [];

  isUploading = false;

  root: RootStore;
  toast = useToast();

  constructor(root: RootStore) {
    this.root = root;
    makeAutoObservable(this);
  }

  //Get All Projects for the Current User
  async getProjects() {
    await admin.VTProjects.GetVTProjects()
      .then((response: GetProjectsResponse) => {
        if (response && response.result !== undefined) {
          this.projects = response.result;
        } else {
          this.toast({
            title: 'Error',
            description: 'Error retrieving projects! - Please try again',
            status: 'error',
            duration: 2000,
            isClosable: true,
          });

          console.log('Error listing Project Error:', response.message);
          this.projects = [];
        }
      })
      .catch((error: any) => {
        this.toast({
          title: 'Error',
          description: 'Error retrieving projects! - Please try again',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });

        console.log('Error listing Project Error:', error);
      });
  }

  //Get All Shared Projects for the Current User
  async getSharedProjects() {
    await admin.SharedProjects.GetSharedProjects()
      .then((response: GetSharedProjectsResponse) => {
        if (response && response.result !== undefined) {
          this.sharedProjects = response.result;
        } else {
          this.toast({
            title: 'Error',
            description: 'Error retrieving Shared Projects! - Please try again',
            status: 'error',
            duration: 2000,
            isClosable: true,
          });

          console.log('Error listing Shared Projects Error:', response.message);
          this.projects = [];
        }
      })
      .catch((error: any) => {
        this.toast({
          title: 'Error',
          description: 'Error retrieving shared projects! - Please try again',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });

        console.log('Error listing Shared Project Error:', error);
      });
  }

  //Compress file Import Ready for Export
  compressFile = async (file) => {
    const imageFile = file;
    const options = {
      maxSizeMB: 2,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      console.log('Compressing Scene Error: ' + error);
    }
  };

  //Create New Tour Project
  async createProject(project: IVTProject) {
    if (project !== undefined) {
      var createProjectReq = {
        Project: project,
      } as CreateProjectRequest;

      

      var createProjectResponse = (await admin.VTProjects.AddVTProject(
        createProjectReq,
      )) as CreateProjectResponse;

      if (createProjectResponse.isSuccess) {
        this.selectedProject = createProjectResponse.result;
        return createProjectResponse.result;
      }
    }
  }

   //Update New Tour Project
   async updateProject(project: IVTProject) {
    if (project !== undefined) {
      var updateProjectReq = {
        Project: project,
      } as UpdateProjectRequest;

      var updateProjectResponse = (await admin.VTProjects.UpdateVTProject(updateProjectReq)) as UpdateProjectResponse;

      if (updateProjectResponse.isSuccess) {
        this.selectedProject = updateProjectResponse.result;
        return updateProjectResponse.result;
      }
    }
  }

  //Delete Tour Project -- And Associated Shared Projects
  async deleteProject(projectId: string) {
    // VT Project

    await admin.VTProjects.DeleteVTProject(projectId)
      .then((result: DeleteProjectResponse) => {
        if (result.isSuccess) {
          this.toast({
            title: 'Success',
            description: 'Project has successfully been deleted.',
            status: 'success',
            duration: 2000,
            isClosable: true,
          });
        } else {
          this.toast({
            title: 'Error',
            description: 'Error deleting project! - please try again',
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
          console.log('Error deleting project Error: ' + result.message);
        }
      })
      .catch((response: any) => {
        this.toast({
          title: 'Error',
          description: 'Error deleting project! - please try again',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });

        console.log('Error deleting project ror: ' + response.message);
      })
      .finally(() => {
        this.getProjects();
        return true;
      });
  }

  //Delete Tour Project -- And Associated Shared Projects
  async deleteSharedProject(projectId: string) {
    // VT Project

    await admin.SharedProjects.DeleteSharedProject(projectId)
      .then((result: DeleteProjectResponse) => {
        if (result.isSuccess) {
          this.toast({
            title: 'Success',
            description: 'Shared Project has successfully been deleted.',
            status: 'success',
            duration: 2000,
            isClosable: true,
          });
        } else {
          this.toast({
            title: 'Error',
            description: 'Error deleting shared project! - please try again',
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
          console.log('Error deleting shared project Error: ' + result.message);
        }
      })
      .catch((response: any) => {
        this.toast({
          title: 'Error',
          description: 'Error deleting shared project! - please try again',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });

        console.log('Error deleting project ror: ' + response.message);
      })
      .finally(() => {
        this.getSharedProjects();
        return true;
      });
  }

  // Validate and Dedupe Scene Uploads for Tour Project
  setProjectUploads(filelist: any) {
    const dedupedFiles = Array.from(new Set(filelist)) as IFileUpload[];
    dedupedFiles.sort((a, b) =>
      a.File.lastModified > b.File.lastModified ? 1 : -1,
    );
    this.projectUploadFiles = dedupedFiles;
  }

  //Remove a file from an upload list (Usually Scenes)
  removeUploadFile(fileName: string, previewURL: string) {
    const updatedUploadList = this.projectUploadFiles.filter(
      (p) => p.File.name !== fileName,
    );
    this.projectUploadFiles = updatedUploadList;

    if (previewURL) {
      URL.revokeObjectURL(previewURL);
    }

    this.toast({
      title: 'Success',
      description: 'File Removed Successfully',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  }

  //Scene Builder - Rename Scene
  SB_RenameScene(filename: string, sceneName: string) {
    const index = this.projectUploadFiles.findIndex(
      (f) => f.File.name === filename,
    );

    if (index !== -1) {
      var updatedProjectUploads = [...this.projectUploadFiles];
      var fileToUpdate = updatedProjectUploads[index];
      fileToUpdate.Name = sceneName;
      updatedProjectUploads[index] = fileToUpdate;
      this.projectUploadFiles = updatedProjectUploads;
    }
  }

  setIsUploading(isUploading) {
    this.isUploading = isUploading;
  }

  // Set the Progress of a File Upload
  setFileUploadProgress = (
    filename: string,
    progress: number,
    status: string,
  ) => {
    console.log('FileName: ' + filename + ' ==> ' + progress);

    var fIndex = this.projectUploadFiles.findIndex(
      (f: IFileUpload) => f.File.name === filename,
    );

    const uploads = [...this.projectUploadFiles];

    var fileToUpdate = this.projectUploadFiles[fIndex] as IFileUpload;

    if (fileToUpdate) {
      fileToUpdate.Progress = progress;
      fileToUpdate.Status = status;

      uploads[fIndex] = fileToUpdate;
      this.projectUploadFiles = uploads;
    }
  };

  //Upload File As Scene for a tour
  uploadScene = async (fUpload: IFileUpload, projectId: string) => {
    this.setIsUploading(true);
    this.setFileUploadProgress(fUpload.File.name, 0, 'Compressing File');

    //Compress
    var compressedFile = await CompressFile(fUpload.File);
    this.setFileUploadProgress(fUpload.File.name, 0, 'File Compressed');
    const base64string = await ConvertBase64(compressedFile);

    var sceneName =
      fUpload.Name !== undefined
        ? fUpload.Name
        : fUpload.File.name.substring(0, fUpload.File.name.lastIndexOf('.'));

    var addNewSceneObj = {
      sceneName: sceneName,
      fileName: fUpload.File.name,
      projectId: projectId,
      fileContent: base64string.toString().split(',')[1],
    };

    var addSceneRequest = {
      action: 'addScene',
      value: addNewSceneObj,
      projectId: projectId
    } as TourActionRequest;

    var response = (await admin.TourActions.Call(
      addSceneRequest,
    )) as TourActionResponse;

    if (response.isSuccess) {
      try {
        URL.revokeObjectURL(fUpload.Preview);
      } catch (err) {
        this.toast({
          title: 'Error',
          description:
            'Error disposing of scene! - Browser may need to be refreshed if performance isnt as expected',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }

      this.setFileUploadProgress(fUpload.File.name, 100, 'Creating Scene');
      this.setFileUploadProgress(fUpload.File.name, 100, 'Scene Created');

      return response.result;
    }
  };

  createTour = async (
    includeBranding: boolean,
    projectfSId: string,
    projectScenes: IScene[],
  ) => {
    if (projectfSId !== undefined && projectScenes.length > 0) {
      var startUpScene = projectScenes[projectScenes.length - 1].name;

      var createTourRequest = {
        hsLinks: this.projectUploadHotspots,
        projectId: projectfSId,
        layers:[],
        scenes: projectScenes,
        startScene: startUpScene,
        branding: includeBranding,
      } as createTourObj;

      var callCreateTourRequest = {
        action: 'createTour',
        projectId: projectfSId,

        value: createTourRequest,
      } as TourActionRequest;

      var response = (await admin.TourActions.Call(
        callCreateTourRequest,
      )) as TourActionResponse;

      this.setIsUploading(false);

      console.log('Create Tour Response: ', response.isSuccess);

      return true;
    } else {
      this.toast({
        title: 'Error',
        description: 'Unable to Create Tour project - Missing Prerequisites!',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  //Shared Projects

  async createSharedProject(sharedProject: ISharedProjectLink): Promise<any> {
    var request = {
      sharedProjectLink: sharedProject,
    } as CreateSharedProjectRequest;

    return await admin.SharedProjects.CreateSharedProject(request)
      .then((response: CreateSharedProjectResponse) => {
        if (response.isSuccess) {
          this.toast({
            title: 'Success',
            description: 'Shared Project has been created successfully',
            status: 'success',
            duration: 2000,
            isClosable: true,
          });

          return response;
        } else {
          console.log(response);

          this.toast({
            title: 'Error',
            description: 'Unable to create Shared Project - Please Try again',
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
        }
      })
      .catch((error: any) => {
        console.log(error.response.data.message);

        this.toast({
          title: 'Error',
          description:
            'Error Creating Shared Project:  ' + error.response.data.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      });
  }

  //Update of Shared Projects
  async updateSharedProject(sharedProject: ISharedProjectLink, refreshContent:Boolean): Promise<any> {


    var sharedProjectReq = {
      sharedProjectLink: sharedProject,
      refreshContent: refreshContent 
    } as UpdateSharedProjectRequest;


    return await admin.SharedProjects.UpdateSharedProject(sharedProjectReq)
      .then((response: UpdateSharedProjectResponse) => {
        if (response.isSuccess) {
          this.toast({
            title: 'Success',
            description: 'Shared Project Successfully Updated!',
            status: 'success',
            duration: 2000,
            isClosable: true,
          });

          return response;
        } else {
          this.toast({
            title: 'Error',
            description:
              'Unable to Create Tour project - Missing Prerequisites!',
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
        }
      })
      .catch((error: any) => {
        this.toast({
          title: 'Error',
          description:
            'Error Updating Shared Project:  ' +
            (error.response.data.message | error.message),

          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      });
  }

  //Regenerate a shared Project
  async regenerateSharedProject(sharedProjectLinkId: number): Promise<any> {
    
    return await admin.SharedProjects.RegenerateSharedProject(
      sharedProjectLinkId,
    )
      .then((response: any) => {
        if (response.isSuccess) {
          this.toast({
            title: 'Success',
            description: 'Shared Project Successfully Regenerated!',
            status: 'success',
            duration: 2000,
            isClosable: true,
          });

          return response;
        } else {
          this.toast({
            title: 'Error',
            description: 'Unable to Regenerated Shared project!',
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
        }
      })
      .catch((error: any) => {
        this.toast({
          title: 'Error',
          description:
            'Unable to Regenerated Shared project!:  ' +
            (error.response.data.message | error.message),

          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      });
  }
}
