import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, createStandaloneToast, GridItem, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, SimpleGrid } from '@chakra-ui/react'


//Data Table 
import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import 'ag-grid-enterprise';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { IUserOrgRole } from '../../../../models/userOrgRole';
import { GetUserRolesResponse, UpdateUserRoleRequest, UpdateUserRoleResponse } from '../../../../models/admin-api/dto';
import admin from '../../../../api/admin';

import UserRoleActionsRowRenderer from './UserRoleActionsRowRenderer';
import OrganisationCellRenderer from '../../../gridRenderers/OrganisationCellRenderer';


import AddUserRole from '../../../userRole/addUserRole';
const { toast } = createStandaloneToast();


const UserRoles = props => {

  const [userRoles, setUserRoles] = useState<IUserOrgRole[]>([]);

  useEffect(() => {

    if (props.user) {
      getRolesForUser();
    }
  }, []);


  const handleRoleChange = async (event: any) => {

    var request = { userClaim: event.data } as UpdateUserRoleRequest

    console.log(request)
    await admin.Users.UpdateUserRole(request, event.data.userClaimId).then(
      (response: UpdateUserRoleResponse) => {

        console.log(response);

        if (response ) {
          toast({
            title: 'Success.',
            description: "User Role Updated Successfully",
            status: 'success',
            duration: 9000,
            isClosable: true,
          });

        };
      })



  }

  async function getRolesForUser() {


    await admin.Users.GetUserRoles(props.user.userId).then(
      (response: GetUserRolesResponse) => {

        console.log(response.result);
        response.isSuccess ? setUserRoles(response.result) : alert("Error Retrieving ROles")
      }
    );
  }


  const colDefs =
    [
      { headerName: "Organisation", field: "organisationId", cellRenderer: OrganisationCellRenderer },
      { headerName: "Create", field: "create", flex: 2, editable: true, cellRenderer: 'agCheckboxCellRenderer', cellEditor: 'agCheckboxCellEditor', type: 'boolean', onCellValueChanged: handleRoleChange },
      { headerName: "Build", field: "build", flex: 1, editable: true, cellRenderer: 'agCheckboxCellRenderer', cellEditor: 'agCheckboxCellEditor', type: 'boolean', onCellValueChanged: handleRoleChange },
      { headerName: "View", field: "view", flex: 1, editable: true, cellRenderer: 'agCheckboxCellRenderer', cellEditor: 'agCheckboxCellEditor', type: 'boolean', onCellValueChanged: handleRoleChange },
      { headerName: "Share", field: "share", flex: 1, editable: true, cellRenderer: 'agCheckboxCellRenderer', cellEditor: 'agCheckboxCellEditor', type: 'boolean', onCellValueChanged: handleRoleChange },
      { headerName: "Delete", field: "delete", flex: 1, editable: true, cellRenderer: 'agCheckboxCellRenderer', cellEditor: 'agCheckboxCellEditor', type: 'boolean', onCellValueChanged: handleRoleChange },
      { headerName: "Actions", field: "delete", cellRenderer: UserRoleActionsRowRenderer },
    ];


  return (
    <SimpleGrid >
      <GridItem >
        <Popover lazyBehavior='keepMounted' isLazy>
          <PopoverTrigger>
            <Button style={{ "marginBottom": 5 }} float={"right"} variant={"brand"}>Add New Role</Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Add New Role</PopoverHeader>
            <PopoverBody>
              <AddUserRole currentOrgs={props.currentOrgs} userOrgRole={undefined} userStore={props.userStore} currentUser={props.currentUser} userId={props.user?.userId} />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </GridItem>

      <GridItem>
        {props.user && props.user.isEnterpriseAdmin !== true ? (
          <div
            className="ag-theme-quartz" // applying the grid theme
            style={{ height: 450 }} // the grid will fill the size of the parent container
          >
            <AgGridReact
              rowData={userRoles}
              columnDefs={colDefs as any}
              pagination
            />
          </div>
        ) : ("No User / User is Enterprise admin!")}

      </GridItem>
    </SimpleGrid>
  )
}

UserRoles.propTypes = {
  user: PropTypes.any,
  currentUser: PropTypes.any,
  userStore: PropTypes.any,
  currentOrgs: PropTypes.any,
  isNew: PropTypes.bool

}

export default UserRoles